@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap');

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.table {
  
  border-collapse: collapse;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.arrowback{
color: #FF8159;
}
.tablebody td{
  border-top: 2px solid #DDDDDD ;
}
.manageadminc{
  margin-left: 74px;
    margin-top: 40px;
}
.table th, .table td {
  padding: 8px;
  border-bottom: 1px medium black; /* Adds a horizontal line on every row */
}

.table th {
  border-bottom: 2px medium black; /* Thicker line for the header */
}

.table .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  cursor: pointer;
}

.table .btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.aasigntaskbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff8159;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  font: normal normal 600 14px;
  letter-spacing: 0px;
  color: #0c1524;
  opacity: 1;
}
.tableclasses th {
  font-size: 14px;
}

.mainassigntask h2 {
  font-size: 18px;
}

/* Login.css */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container {
  width: 300px;
}

.links {
  text-decoration: none;
}

.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.register-container {
  width: 300px;
}
.dropdown select {
  background-color: white;
  color: black;
}

.maindiv {
  margin: 20px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-thead {
  background: var(--Light-blue, #d4eff8);
}

.custom-table th,
.custom-table td {
  padding: 8px;
  text-align: left;
}

.custom-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

.text-center {
  text-align: center;
}

.bg-black {
  background-color: black;
}

.text-white {
  color: white;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.links {
  color: black;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}

.btn-column {
  display: flex;
  flex-direction: column;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
}

.login-container {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  width: 100%;
  max-width: 400px;
}

.text-center {
  text-align: center;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-3 .btn {
  width: 100%;
}



.table-container {
  width: 80%;
  margin: 0 auto; /* Center the table */
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.mainh2manage{
  margin-left: 150px;
}

.table th, .table td {
  padding: 8px;
  border-bottom: 1px medium black; /* Adds a horizontal line on every row */
}

.table th {
  border-bottom: 2px medium black; /* Thicker line for the header */
}

.table .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  cursor: pointer;
}

.table .btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

h2.text-start {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left: 163px;
  margin-right: 20px;
}
.navabrbg{
  background: #EEF2F6 0% 0% no-repeat padding-box;
opacity: 1;
}

.container input{
  width: 25%;
}
.userbtn{
  border: none;
    background: none;
}